import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Card, CardBody, CardTitle, CardFooter, Label, Row, Col } from 'reactstrap';
import { toPercTable } from 'helpers/formatter';
import FavoriteButton from './FavoriteButtonPreload';

const variants = ['text-warning', 'text-danger', 'text-success'];

const PercCardTitle = ({ value }) => {
  const processed = toPercTable(value);
  if (_.isObject(processed)) {
    return (
      <CardTitle tag="p" style={processed.style}>
        {processed.value}
      </CardTitle>
    );
  } else {
    return <CardTitle tag="p">{processed}</CardTitle>;
  }
};

function AssetHeader({ asset, database, tableData, boxData = {}, children }) {
  const boxCount = _.keys(boxData).length;

  let assetName = null;
  if (asset) {
    assetName = (
      <div className="tw-text-center">
        <h3 className="title tw-mb-2">
          {asset}
          <FavoriteButton asset={asset} database={database} className="tw-float-right" />
        </h3>
      </div>
    );
  }

  return (
    <>
      {assetName}
      {children}
      {!_.isEmpty(tableData) && (
        <Card>
          <CardBody className="h5 tw-mb-0">
            {_.map(tableData, (val, key) => {
              return (
                <Row className="mb-2" key={key}>
                  <Col sm="3" className="text-right tw-text-[1.1rem]">
                    <Label>{key}</Label>
                  </Col>
                  <Col sm="7" className="tw-text-left tw-text-[1rem] tw-font-bold">
                    {val}
                  </Col>
                </Row>
              );
            })}
          </CardBody>
        </Card>
      )}

      {boxCount > 0 && (
        <div className={classNames('tw-grid tw-grid-cols-1 tw-gap-4', `md:tw-grid-cols-${boxCount * 2}`)}>
          {_.map(_.keys(boxData), (key, ind) => {
            const val = boxData[key];

            return (
              <div className="md:tw-col-span-2" key={key}>
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className={classNames('nc-icon nc-money-coins', `${variants[ind % variants.length]}`)} />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <div className="text-right tw-text-[1.1rem]">
                            <Label className="mb-0">{key}</Label>
                          </div>
                          <PercCardTitle value={val} />
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter></CardFooter>
                </Card>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default AssetHeader;
