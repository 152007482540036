import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { StarIcon as EmptyStarIcon } from '@heroicons/react/24/outline';
import { StarIcon as SolidStarIcon } from '@heroicons/react/24/solid';
import { createWatchlist, deleteWatchlist } from 'services/watchlist';
import { getWatchlist } from 'services/watchlist';
import classnames from 'classnames';

function FavoriteButtonPreload({ asset, database, className }) {
  const [bookmarked, setBookmarked] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkBookmarked = async (asset, database) => {
    setLoading(true);
    const [data, error] = await getWatchlist(asset, database);

    if (error) {
      setBookmarked(false);
    } else {
      setBookmarked(!!data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!asset || !database) return null;
    checkBookmarked(asset, database);
  }, [asset, database]);

  if (!asset || !database) return null;

  return (
    <button
      type="button"
      className={classnames(
        'tw-px-2 tw-bg-white tw-border-solid tw-border-1 tw-border-gray-400 tw-rounded-lg hover:tw-bg-gray-100',
        className,
      )}
      onClick={async () => {
        if (bookmarked) {
          deleteWatchlist(asset, database);
          setBookmarked(false);
        } else {
          createWatchlist(asset, database);
          setBookmarked(true);
        }
      }}
    >
      {loading ? (
        <span></span>
      ) : bookmarked ? (
        <SolidStarIcon className="tw-h-6 tw-w-6 tw-text-amber-300" aria-hidden="true" />
      ) : (
        <EmptyStarIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
      )}
    </button>
  );
}

export default FavoriteButtonPreload;
