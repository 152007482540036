import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { StarIcon as EmptyStarIcon } from '@heroicons/react/24/outline';
import { StarIcon as SolidStarIcon } from '@heroicons/react/24/solid';
import { createWatchlist, deleteWatchlist } from 'services/watchlist';
import classnames from 'classnames';

function FavoriteButton({ asset, database, className, selected = false }) {
  const [bookmarked, setBookmarked] = useState(selected);

  if (!asset || !database) return null;

  return (
    <button
      type="button"
      className={classnames(
        'tw-px-2 tw-bg-white tw-border-solid tw-border-1 tw-border-gray-400 tw-rounded-lg hover:tw-bg-gray-100',
        className,
      )}
      onClick={async () => {
        if (bookmarked) {
          deleteWatchlist(asset, database);
          setBookmarked(false);
        } else {
          createWatchlist(asset, database);
          setBookmarked(true);
        }
      }}
    >
      {bookmarked ? (
        <SolidStarIcon className="tw-h-6 tw-w-6 tw-text-amber-300" aria-hidden="true" />
      ) : (
        <EmptyStarIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
      )}
    </button>
  );
}

export default FavoriteButton;
