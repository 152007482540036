import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';

function BackButton({ pathname, additionalSearch = '' }) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Button
      className="tw-mb-0"
      color="default"
      onClick={() => {
        navigate(
          {
            pathname,
            search: `${location.search}&${additionalSearch}`,
          },
          { replace: false },
        );
      }}
    >
      Back
    </Button>
  );
}

export default BackButton;
